 import React from 'react';

 class About extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
   render(){
     return(
       <div className="about-container">
         <div>
           <div>
            <h1>Notre histoire</h1>
            <p>Le Golf de Béthune est un golf dit "urbain" créé en 1996 sous sa forme actuelle et à moins d'un kilomètre de la Grand Place.</p>
            <p>Un parcours de 9 trous et un parcours pitch & putt, plat et boisé, idéal pour s'initier, s'entrainer ou se perfectionner. Le golf se compose d'une sucession de par 3 sur environ 1 kilomètre. Leurs distances varient de 80 à 195 mètres.</p>
            <p>Un practice de 17 postes (dont 9 couverts) est à votre disposition.</p>
           </div>
         </div>
         <div className="about-image"/>
       </div>
     )
   }
 }

 export default About;
