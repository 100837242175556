import React from 'react';


class Mosaic extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          modalIsOpen : false,
          photoToDisplay : {url : "", index : 0}
      }
  }

  openImg = (e, num) => {
    const url = e.target.style.backgroundImage.split('"');
    this.setState({modalIsOpen : true, photoToDisplay : {url : url[1], index : num}});
  }

  closeImg = () => {
    this.setState({modalIsOpen : false, photoToDisplay : {url : "", index : 0}})
  }

  next(index){
    const newIndex = index + 1;
    const newPhoto = this.props.photoList.find((photo, num) => num === newIndex);
    this.setState({photoToDisplay : {url : newPhoto, index : newIndex}});
  }

  previous(index){
    const newIndex = index - 1;
    const newPhoto = this.props.photoList.find((photo, num) => num === newIndex);
    this.setState({photoToDisplay : {url : newPhoto, index : newIndex}});
  }

  render(){
    return(
      <div className="gallery"> 
        {this.state.modalIsOpen === true ? <div className="modal">
                                            <img src={this.state.photoToDisplay.url} alt="cours de golf" />
                                            <button onClick={this.closeImg}>X</button>
                                            <button className={this.state.photoToDisplay.index === 0 ? "none" : "btn-prev"} onClick={() => this.previous(this.state.photoToDisplay.index)}>{"<"}</button>
                                            <button className={this.state.photoToDisplay.index === this.props.photoList.length - 1 ? "none" : "btn-next"} onClick={() => this.next(this.state.photoToDisplay.index)}>{">"}</button>
                                           </div> : ''
        }
        {this.props.photoList.map((photo, index) => {
            return(
                <div className="img" style={{backgroundImage : "url(" + photo + ")"}} key={index} onClick={e => this.openImg(e, index)}/>
            )
        })}
      </div>
    )
  }
}

export default Mosaic;
