import React from 'react';
import bg from '../Assets/Img/as-bg.JPG';
import faceimg from '../Assets/Img/portrait.png';
import faceimg2 from '../Assets/Img/portrait2.png';

class SportAssociation extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render(){
    return(
      <div className="as" style={{backgroundImage : "url(" + bg + ")"}}>
        <div className="card">
          <h1>L’Association Sportive A.S.G.C.B.</h1>
          <p>L’association, créée en 1996, participe activement à la vie sportive et amicale du club. Composée de bénévoles, l’ASGCB accompagne, en liaison avec le gestionnaire du golf, le calendrier des compétitions et des animations.</p>
          <p>Elle est impliquée dans la vie quotidienne du club, via les rencontres sportives ou amicales, les repas, les sorties qui émaillent le calendrier.</p>
          <p>Vous aimez notre parcours ? Vous appréciez les rencontres conviviales ? Vous voulez faire de nouvelles connaissances ?</p>
          <p>Une seule chose à faire : devenir adhérent à l’asso !</p>
        </div>
        <div className="member">
            <h1>Bureau</h1>
            <div className="bureau-member">
            <div className="member-card">
                <img alt="membre de l'as du golf de Béthune" src={faceimg}/>
                <h4>Co-président :</h4><h4>Didier Migliore</h4>
              </div>
              <div className="member-card">
                <img alt="membre de l'as du golf de Béthune" src={faceimg}/>
                <h4>Co-président :</h4><h4>Guillaume Herman</h4>
              </div>
              <div className="member-card">
                <img alt="membre de l'as du golf de Béthune" src={faceimg2}/>
                <h4>Secrétaire :</h4><h4>Frédérique Buchwald</h4>
              </div>
              <div className="member-card">
                <img alt="membre de l'as du golf de Béthune" src={faceimg}/>
                <h4>Trésorier :</h4><h4>Manuel Lauwers</h4>
              </div>
            </div>
        </div>
        <div className="card-tarif">
          <h1>Cotisation 2024</h1>
          <ul>
            <li>Adulte: 35 €</li>
            <li>Couple/Duo (même adresse): 60 €</li>
            <li>Junior (-18 ans): 25 €</li>
            <li>Jeune école de golf: 10 €</li>
          </ul>
        </div>
      </div>
    )
  }
}

export default SportAssociation;
