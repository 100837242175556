import React from 'react';
import Facebook from '../Assets/Img/facebook.png';
import Instagram from '../Assets/Img/instagram.png';
import Maps from '../Assets/Img/maps.png';

class Rs extends React.Component {
  render(){
    return(
      <div className="rs">
        <a
          href="https://www.facebook.com/golfdebethunepageofficielle/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Facebook} alt="Facebook"/>
        </a>
        <a
          href="https://www.instagram.com/golfdebethune/"
          target="_blank"
          rel="noopener noreferrer"
          >
          <img src={Instagram} alt="TwiInstagram"/>
        </a>
        <a
          href="https://www.google.com/maps/place/Golf+de+Béthune/@50.525664,2.6260533,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x989720100b093f4c!8m2!3d50.525664!4d2.628242"
          target="_blank"
          rel="noopener noreferrer"
          >
          <img src={Maps} alt="Google Maps"/>
        </a>
      </div>
    )
  }
}

export default Rs;
