import React from 'react';
import { Link } from 'react-router-dom';
import Mosaic from './../Components/Mosaic';
import formulaire from "./../Assets/Pdf/Inscription2024.pdf";
import questionnaire from "./../Assets/Pdf/questionnaire-medical.pdf";
import planning from"./../Assets/Pdf/planning2024.pdf";
import image from './../Assets/Img/golf15.JPG';
import image2 from './../Assets/Img/golf16.JPG';
import image3 from './../Assets/Img/golf14.JPG';
import image4 from './../Assets/Img/golf3.jpg';
import image5 from './../Assets/Img/golf17.JPG';
import image6 from './../Assets/Img/golf18.JPG';
import image7 from './../Assets/Img/golf19.JPG';
import image8 from './../Assets/Img/golf20.jpg';
import image9 from './../Assets/Img/golf21.jpg';
import image10 from './../Assets/Img/golf22.jpg';

class School extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        photoList : [
          image,
          image2,
          image3,
          image4,  
          image5,
          image6,
          image7,
          image8,  
          image9,  
          image10,     
        ]
    }
}
  componentDidMount() {
    window.scrollTo(0,0);
  }

  render(){
    return(
      <div className="school">
        <div className="school-info-conatianer">
          <div className="school-text-container">
            <h1>Ecole de Golf</h1>
            <h4>Label Développement</h4>
            <br/>
            <p>L’Ecole de Golf accueille les enfants dès l’âge de 7 ans.</p>
            <p>Les cours ont lieu les mercredis de 15h00 à 16h30 et les samedis de 10 à 12 h.</p>
            <p>Le matériel est fourni, possibilité d'achat de 1/2 série pour débutant dans notre boutique.</p>
            <p>Tarif 2024 : 250 € - Licence obligatoire (21 à 24 € selon l’âge de l’enfant) - Adhésion ASGCB 10€</p>   
            <div className="school-btn"> 
                <a href={formulaire} target="blank">
                  <button>Télécharger le formulaire d'inscription</button>
                </a>
            </div>  
            <div className="school-btn"> 
                <a href={planning} target="blank">
                  <button>Consulter le planning</button>
                </a>
            </div> 
            <div className="school-btn"> 
                <a href={questionnaire} target="blank">
                  <button>Questionnaire Médical</button>
                </a>
            </div> 
          </div>
          <div className="school-text-container">
            <h1>Baby Golf</h1>
            <p>Nous accueillons les enfants de 4 à 7 ans en petit groupe et aux beaux jours.</p>
            <p>L'approche du golf se fait de manière ludique avec du matériel adapté et des balles en mouses tout en développant les qualités motrices, physiques et mentales des tous petits.</p>
            <p>Le groupe de 6 enfants est encadré par notre professionnel Jean-François, le cours dure 4 x 45 minutes le dimanche matin.</p>
            <p>Tarif 2023 : 70 € - Licence obligatoire (19 € ou 21 €)</p>
            <div className="school-btn">
               <Link to="/contact"><button>Nous contacter pour une inscription</button></Link>
            </div>  
          </div>
        </div>
        <Mosaic 
          photoList={this.state.photoList}
        />
      </div>
    )
  }
}

export default School;
