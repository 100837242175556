import React from 'react';
import image2 from './../Assets/Img/part2.png';
import image3 from './../Assets/Img/part3.jpg';
import image4 from './../Assets/Img/part4.jpg';
import image5 from './../Assets/Img/part5.jpg';
import image7 from './../Assets/Img/part7.jpg';
import image8 from './../Assets/Img/part8.jpg';
import image9 from './../Assets/Img/part9.png';


class Partenaires extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      imgList : [
        
        {img : image2, url : "https://swisslife.fr"},
        {img : image3, url : "https://www.profilwood.eu/fr/entreprises/entreprise/564:societe-nouvelle-hunet"},
        {img : image5, url : "https://www.beckerman-toiture-menuiserie.fr"},
        {img : image8, url : "https://www.intothegreen.fr"},
        {img : image9, url : "https://www.goliat.fr/"},
        {img : image4, url : "https://www.bernard-beck.fr"},
      ]
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render(){
    return(
      <div className="partner">
        <div className="city-logo">
          <img alt="logo Ville de Béthune" src={image7}/>
        </div>
        <div className="partner-container">
          {this.state.imgList.map((logo, index) => {
            return(
              <div key={index}>
                <a href={logo.url} target="blank">
                  <img alt="logo partenaire" src={logo.img}/>
                </a>
              </div>
            )
          })}
        </div>
      </div>

    )
  }
}

export default Partenaires;
