import React from 'react';
import image from './../Assets/Img/seminaire.png';
import image2 from './../Assets/Img/voeuxpartenaires2024.png';
import bg from './../Assets/Img/news-bg.jpg';

class News extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      photoToDisplay : "",
      modalIsOpen : false,
      newsList : [
        {title: "Bonne est heureuse année 2024", date : "", photo : image2, message : "Le Golf de Béthune vous souhaite une année pleine d'énergie &\nde beaux projets\n\nRemplie de joie, de bonheur et de réussite.\nAvec une excellente santé pour vous et vos proches."},
        //{title: "Votre prochain séminaire au golf de Béthune", date : "", photo : image, message : "www.golfdebethune.fr"},
      ]
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  openModal = (img) => {
    this.setState({modalIsOpen : true, photoToDisplay : img})
  }

  closeImg = () => {this.setState({modalIsOpen : false})}

  render(){
    const footer = document.getElementsByTagName('footer');
    const rs = document.getElementsByClassName('rs');
    const heightTotal = window.innerHeight;
    let rsHeight = 0;
    let footerHeight = 0;

    if (footer[0] !== undefined && rs[0] !== undefined) {
      footerHeight = footer[0].clientHeight
      rsHeight = rs[0].clientHeight;
    }

    let heightToRemouve = footerHeight + rsHeight;
    let heighFinal = heightTotal - heightToRemouve;
    const date = new Date;
    return(
      <div className="news" style={{backgroundImage : "url(" + bg + ")", height : heighFinal, backgroundRepeat : "no-repeat", backgroundSize : "cover"}}>
        {this.state.modalIsOpen === true ? <div className="modal">
                                            <img src={this.state.photoToDisplay} alt="visuel actualité" />
                                            <button onClick={this.closeImg}>X</button>
                                           </div> : ''
        }
        {this.state.newsList.map((news, index) => {
          if(date.getDate() < 32 && date.getMonth() < 1 && date.getFullYear() === 2024){
            return(
              <div className="card" key={index}>
                <div style={{backgroundImage : "url(" + news.photo + ")", cursor : "pointer"}} className="img" onClick={() => this.openModal(news.photo)}/>
                <div className="news-txt">
                  <div className="card-header">
                    <h1>{news.title}</h1>
                    <p>{news.date}</p>
                  </div>
                  <pre>{news.message}</pre>
                </div>
              </div>
            );
          }
          
        })}
      </div>
    )
  }
}

export default News;

