import React from 'react';

class Footer extends React.Component {
  render(){
    return(
      <footer>
        ©2020 Golf de Béthune <span>-</span> <a href="https://www.linkedin.com/in/guillaume-depretz-b372a395" target="blank">Created by Guillaume Depretz.</a>
      </footer>
    )
  }
}

export default Footer;
