import React from 'react';

class Prices extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      date : new Date()
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render(){
    return(
      <div>
          <div className="tarif-cover" >
            <h1>Tarifs {this.state.date.getFullYear()}</h1>
          </div>
          <div className="tarif-green-fees">
            <div>
              <h2>Green Fees</h2>
              <p><b>Semaine</b></p>
              <ul>
                <li>Adulte 23 €</li>
                <li>Junior (-18ans) semaine 17 €</li>
              </ul>
              <p><b>Week-end et jours fériés</b></p>
              <ul>
                <li>Adulte 27 €</li>
                <li>Junior (-18ans) semaine 19 €</li>
              </ul>
            </div>
            <div>
              <h2>Practice</h2>
              <ul>
                <li>1 seau de balle (environ 40 balles) 3,40 €</li>
                <li>10 seaux de balle 29 €</li>
              </ul>
              <br/>
              <h2>Autres prestations</h2>
              <ul>
                <li>Location Golfette 12€</li>
                <li>Prêt de Clubs et Chariots gratuit</li>
              </ul>
            </div>
            <div>
              <h2>Licences*</h2>
              <p style={{fontSize:"15.5px"}}>*du 1er janvier au 31 décembre 2024</p>
                <ul>
                  <li>Adulte (+25 ans, nés avant 1999) 60 €</li>
                  <li>Jeune adulte (de 19 à 25 ans, né de 1999 à 2005 inclus) 36 €</li>
                  <li>Jeune (de 13 à 18 ans, né de 2006 à 2011 inclus) 24 €</li>
                  <li>Enfant (moins 13 ans, né à partir de 2012 inclus) 21 €</li>
                </ul>
            </div>
          </div>
          <div className="tarif-cover2" >
            <h1>Abonnements {this.state.date.getFullYear()}</h1>
            <p style={{position : "absolute", left: "10px", bottom : "0px", fontSize : "20px"}}></p>
          </div>
          <div className="tarif-annual">
            <div className="tarif-annual-card">
              <div className="tarif-annual-text">
                <h2 style={{marginBottom : "15px"}}>Adulte</h2>
                <p>Béthunois 460€</p>
                <p>Extérieurs 520€</p>
              </div>
              <div className="tarif-card1"/>
            </div>
            <div className="tarif-annual-card">
              <div className="tarif-annual-text">
                <h2 style={{marginBottom : "15px"}}>Couple</h2>
                <p>Béthunois 610€</p>
                <p>Extérieurs 670€</p>
              </div>
              <div className="tarif-card2"/>
            </div>
            <div className="tarif-annual-card">
              <div className="tarif-annual-text">
                <h2 style={{marginBottom : "15px"}}>Jeune</h2>
                <p>Etudiant (sur justificatif) 290€</p>
                <p>Junior (-18 ans) 240€</p>
              </div>
              <div className="tarif-card3"/>
            </div>
          </div>
      </div>
    )
  }
}

export default Prices;
