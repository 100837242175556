 import React from 'react';
 import Img from '../Assets/Img/background.jpg';
 import Img2 from '../Assets/Img/home-bg.JPG';
 import Form from '../Components/Form.jsx';
 import { Link } from 'react-router-dom';

 class Home extends React.Component {

   constructor(props) {
     super(props);
     this.state = {
      name : "",
      email : "",
      object : "",
      message : "",
      tel : "",
      errorMessage : "",
      validateMessage : ""
    }
   }

   componentDidMount() {
    window.scrollTo(0,0);
  }

  changeInputValue = (evt, inputName) => {
    switch (inputName) {
      case "name":
        this.setState({name : evt.target.value})
        break;
      case "email":
        this.setState({email : evt.target.value})
        break;
      case "tel":
        this.setState({tel : evt.target.value})
        break;
      case "object":
        this.setState({object : evt.target.value})
        break;
      case "message":
        this.setState({message : evt.target.value})
        break;
      default:
        console.error(evt);
        break;
    }
  }

  send = (e) => {
    e.preventDefault();
    console.log(this.state);

    if(this.state.name === ""){
      this.setState({errorMessage : "Veuillez renseigner votre nom"});
    }
    else if(this.state.email === ""){
      this.setState({errorMessage : "Veuillez renseigner votre E-mail"});
    }
    else if(this.state.tel === ""){
      this.setState({errorMessage : "Veuillez renseigner votre numéro de téléphone"});
    }
    else if(this.state.object === ""){
      this.setState({errorMessage : "Veuillez renseigner l'objet de votre message"});
    }
    else if(this.state.message === ""){
      this.setState({errorMessage : "Veuillez écrire un Message"});
    }
    else{
      fetch("http://golfdebethune.fr/email/email.php", {
        method: 'POST',
        body: JSON.stringify({name: encodeURI(this.state.name), object: encodeURI(this.state.object), email : this.state.email, tel : encodeURI(this.state.tel), message : encodeURI(this.state.message)})
      })
      .catch(function(err) {
        alert(err);
      });
      this.setState({
        name : "",
        email : "",
        tel : "",
        object : "",
        message : "",
        errorMessage : "",
        validateMessage : "Message envoyé"
      })
    }
  }

   render(){
     return(
       <div>
         <div className="home-cover" style={{backgroundImage : "url(" + Img2 + ")"}}>
           <h1>
             Golf de Béthune
           </h1>
           <h2>Golf 9 trous</h2>
           <h2>Pitch and putt</h2>
           <h3>Golf - Enseignement - Séminaire - Réception</h3>
           <Link to="/a-propos"><button>En savoir plus</button></Link>
         </div>
         <div className="home-information-raw">
           <div className="home-information-block" style={{backgroundImage : "url(" + Img + ")"}}/>
           <div className="home-information-block">
             <h2>Nous contacter</h2>
             <h3>Rue du Dr Dhenin, 62400 Béthune, France</h3>
             <h3>Tél : 03 21 57 13 13</h3>
             <Form 
              send={this.send}
              changeInputValue={this.changeInputValue}
              name={this.state.name}
              email={this.state.email}
              tel={this.state.tel}
              object={this.state.object}
              message={this.state.message}
              errorMessage={this.state.errorMessage}
              validateMessage={this.state.validateMessage}
             />
           </div>
         </div>
         <a href="https://www.google.com/maps/place/Golf+de+Béthune/@50.525664,2.6260533,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x989720100b093f4c!8m2!3d50.525664!4d2.628242" target="blank">
          <div className="map"/>
         </a>
       </div>
     )
   }
 }

 export default Home;
