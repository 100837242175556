import React from 'react';
import img1 from './../Assets/Img/sem2.JPG';
import img2 from './../Assets/Img/sem1.JPG';
import img3 from './../Assets/Img/sem3.JPG';
import img4 from './../Assets/Img/sem5.JPG';
import img5 from './../Assets/Img/sem4.JPG';
import CGV from "./../Assets/Pdf/CGV-Seminaires.pdf";

class Seminaire extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render(){
    return(
    <div className="seminaire">
      <div className="sem-block">
        <div className="sem-card">
          <h1>LES SEMINAIRES</h1>
          <p>Nous recevons vos séminaires ou réunions de travail tout au long de l’année en semaine comme en week-end dans un espace privatif.</p>
          <br/>
          <p>Notre salle de 120 m² donnant sur le green du golf est équipée d’un vidéoprojecteur, d’un écran motorisé, d’un paperboard et d’une sonorisation. Ses baies vitrées et sa terrasse en font un lieu idéal baigné dans une lumière naturelle.</p>
        </div> 
        <div className="sem-img" style={{backgroundImage : "url(" + img1 + ")"}} />
      </div>
      <div className="sem-block">
        <div className="sem-img" style={{backgroundImage : "url(" + img2 + ")"}} />
        <div className="sem-card">
          <h1>ESPACE DE TRAVAIL</h1>
          <ul>
            <li>Salle de 120 m2</li>
            <li>Capacité de 8o pers. debout</li>
            <li>Capacité de 60 pers. en mode "théâtre"</li>
            <li>Capacité de 20 pers. en mode "U"</li>
            <li>Accès à un espace terrasse et jardin</li>
            <li>Vidéoprojecteur, sono et paperboard</li>
          </ul>
        </div> 
      </div>
      <div className="sem-block">
        <div className="sem-card">
          <h1>PRESTATIONS</h1>
          <ul>
            <li>Petit déjeuner d'accueil</li>
            <li>Cocktail</li>
            <li>Formule dînatoire</li>
            <li>Déjeuner servis à table</li>
            <li>Buffet</li>
            <li>Barbecue</li>
          </ul>
        </div> 
        <div className="sem-img"  style={{backgroundImage : "url(" + img3 + ")"}}/>
      </div>
      <div className="sem-block">
        <div className="sem-img" style={{backgroundImage : "url(" + img4 + ")"}} />
        <div className="sem-card">
          <h1>GOLF - PETANQUE</h1>
          <div className="sem-list-box">
            <div>
              <h3>INITIATION AU GOLF</h3>
              <ul>
                <li>Initiation au golf</li>
                <li>Mise à disposition du matériel</li>
                <li>Découverte des 3 espaces golfiques</li>
                <li>Apprentissage du swing au practice</li>
                <li>Travail d'approche au putting-green</li>
                <li>Mise en situation sur le parcours 9 trous</li>
                <li>Mise à disposition du boulodrome</li>
              </ul>
            </div>
            <div>
              <h3>TEAM BUILDING</h3>
              <ul>
                <li>Initiation au golf</li>
                <li>Mise en place de challenge encadré par notre professionnel de golf</li>
                <li>Challenge convivial ou compétitif</li>
                <li>Individuel ou par équipe</li>
                <li>Podium et remise de cadeaux</li>
              </ul>
            </div>
            </div>
        </div> 
      </div>
      <div className="sem-block">
        <div className="sem-card">
          <h1>INFORMATIONS</h1>
          <h3>Exemples de prix HT par personne</h3>
          <ul>
            <li>Salle équipée à partir de 15 € ht</li>
            <li>Petit-déjeuner à partir de 6 € ht</li>
            <li>Déjeuner (2 plats, eau plate et pétillante) à partir de 29 € ht</li>
            <li>Formule dinatoire à partir de 23 € ht</li>
            <li>Initiation au golf à partir de 14 € ht</li>
          </ul>
          <div className="CGV-btn"> 
                <a href={CGV} target="blank">
                  <button>Consulter les Conditions Générales de Vente</button>
                </a>
            </div> 
        </div> 
        <div className="sem-img" style={{backgroundImage : "url(" + img5 + ")"}} />
      </div>
    </div>
    )
  }
}

export default Seminaire;
