import React from 'react';
import Mosaic from './../Components/Mosaic';
import image from './../Assets/Img/proshop.JPG';
import image2 from './../Assets/Img/proshop2.JPG';
import image3 from './../Assets/Img/proshop3.JPG';
import image4 from './../Assets/Img/proshop4.JPG';
import image5 from './../Assets/Img/proshop5.JPG';
import image6 from './../Assets/Img/proshop6.JPG';
import image7 from './../Assets/Img/proshop7.JPG';
import image8 from './../Assets/Img/proshop8.JPG';
import image9 from './../Assets/Img/proshop9.JPG';
import image10 from './../Assets/Img/proshop10.JPG';
import image11 from './../Assets/Img/proshop11.JPG';
import image12 from './../Assets/Img/proshop12.JPG';
import image13 from './../Assets/Img/proshop1.JPG';
import image14 from './../Assets/Img/proshop14.JPG';
import image15 from './../Assets/Img/proshop15.JPG';
import image16 from './../Assets/Img/proshop16.JPG';
import image17 from './../Assets/Img/proshop17.JPG';
import image18 from './../Assets/Img/proshop18.JPG';
import image19 from './../Assets/Img/proshop19.JPG';
import image20 from './../Assets/Img/proshop20.jpg';
import image21 from './../Assets/Img/proshop21.JPG';
import image22 from './../Assets/Img/proshop22.jpg';

class Proshop extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        photoList : [
          image,
          image2,
          image3,
          image4,  
          image5,
          image6,
          image7,
          image8,     
          image9,
          image10,  
          image11,
          image12,
          image13,
          image14,
          image15,
          image16,
          image17,
          image18,
          image19,
          image20,
          image21,
          image22,
        ]
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render(){
    return(
      <div className="proshop">
        <Mosaic
          photoList={this.state.photoList}
        />
      </div>
    )
  }
}

export default Proshop;
