import React from 'react';

class Form extends React.Component {
  render(){
    return(
      <form onSubmit={(e) => this.props.send(e)}>
        <div>
          <input type="text" placeholder="Nom" onChange={e => this.props.changeInputValue(e, "name")} value={this.props.name} />
          <input type="tel" placeholder="Téléphone" onChange={e => this.props.changeInputValue(e, "tel")} value={this.props.tel}/>
        </div>
        <input type="email" placeholder="Email" onChange={e => this.props.changeInputValue(e, "email")} value={this.props.email}/>
        <input type="text" placeholder="Objet" onChange={e => this.props.changeInputValue(e, "object")} value={this.props.object}/>
        <textarea placeholder="Mon Message" onChange={e => this.props.changeInputValue(e, "message")} value={this.props.message}/>
        <div style={this.props.errorMessage !== "" ? {background : "red", color : "white", padding : "10px", display : "block", textAlign : "center", borderRadius : "50px", margin : "10px", fontWeight : "bold"} : {display : "none"}}>
            {this.props.errorMessage}
          </div>
          <div style={this.props.validateMessage !== "" ? {background : "#B4D345", color : "white", padding : "10px", display : "block", textAlign : "center", borderRadius : "50px", margin : "10px", fontWeight : "bold"} : {display : "none"}}>
            {this.props.validateMessage}
          </div>
        <input type="submit" value="Envoyer"/>
      </form>
    )
  }
}

export default Form;
