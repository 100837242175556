import React from 'react';
import { Link } from 'react-router-dom';

class Courses extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }
  
  render(){
    return(
      <div className="courses">
          <div className="courses-cover">
            <h1>Cours</h1>
          </div>
          <div className="tarif-annual">
            <div className="tarif-annual-card">
              <div className="tarif-card1"/>
              <div className="tarif-annual-text">
                <h2>Cours individuels</h2>
                <p>Sur Rendez-vous</p>
                <ul>
                  <li>Leçon individuelle  30 minutes : 30€</li>
                  <li>Leçon individuelle  1 heure : 50€</li>
                  <li>Leçon en duo 1 heure : 60€</li>
                </ul>
              </div>
            </div>
            <div className="tarif-annual-card" style={{height : "915px"}}>
              <div className="tarif-card3"/>
              <div className="tarif-annual-text">
                <h2>Cartes Vertes</h2>
                <p><b>Nous vous proposons 3 formules de cours :</b></p>
                <p><b>Formule Birdie :</b><br/>4 x ½ h de cours individuel + 1 h d’accompagnement sur le parcours – 10 jetons de practice – l’accès au parcours – prêt d’une ½ série</p>
                <p><b>Formule Eagle :</b><br/>8 x ½ h de cours individuel + 1 h d’accompagnement sur le parcours – 10 jetons de practice – l’accès au parcours – prêt d’une ½ série</p>
                <p><b>Formule Albatros :</b><br/>10 x ½ h de cours individuel + 2 h d’accompagnement sur le parcours – 20 jetons de practice – l’accès au parcours – prêt d’une ½ série</p>
                <p>(validité des formules 4 mois)</p>
                <p>(cours sur rdv)</p>
              </div>
            </div>
            <div className="tarif-annual-card">
              <div className="tarif-card2"/>
              <div className="tarif-annual-text">
                <h2>Initiation – Découverte – Carte Cadeau</h2>
                <p>Organisation sur rdv d’initiation, de découverte du golf entre amis.</p>
                <p>Formule à déterminer.</p>
              </div>
            </div>
          </div>
          <div className="courses-contact">
            <Link to="/contact"><button>Nous contacter pour reserver votre cours</button></Link>
          </div>
      </div>
    )
  }
}

export default Courses;
