import React from 'react';

class Backoffice extends React.Component {
  render(){
    return(
      <div>
        Backoffice
      </div>
    )
  }
}

export default Backoffice;
