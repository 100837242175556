import React from 'react';
import Logo from './../Assets/Img/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

class Header extends React.Component {
  render(){
    return(
      <>
      <header>
        <div className="header-info"><p><FontAwesomeIcon icon={faPhoneAlt} />  03 21 57 13 13</p> <p><FontAwesomeIcon icon={faMapMarkerAlt} /> Rue du Dr Dhenin, 62400 Béthune, France</p></div>
        <div style={{display : "flex", alignItems : "center", justifyContent : "space-between"}}>
          <div style={{display : "flex", alignItems : "center"}}>
            <img src={Logo} alt="Logo du golf de Béthune"/>
            <h1>Golf de Béthune, Le Golf pour tous</h1>
          </div>
          <button className="header-cnt-btn"><Link to="/contact">Contact</Link></button>
        </div>

        <div
          className="menu"
          onClick={this.props.changeModbileMenuDisplay}
        >
          <div style={{width:"30px", height:"5px", borderRadius:"20px", margin:"5px 0" ,background:"black"}} />
          <div style={{width:"30px", height:"5px", borderRadius:"20px", margin:"5px 0" ,background:"black"}} />
          <div style={{width:"30px", height:"5px", borderRadius:"20px", margin:"5px 0" ,background:"black"}} />
        </div>
      </header>
      </>
    )
  }
}

export default Header;
