import React from 'react';
import Mosaic from './../Components/Mosaic';
import img1 from './../Assets/Img/recep2.JPG';
import img2 from './../Assets/Img/recep4.JPG';
import img3 from './../Assets/Img/recep3.JPG';
import img4 from './../Assets/Img/recep1.JPG';
import img5 from './../Assets/Img/recep5.JPG';
import img6 from './../Assets/Img/recep6.JPG';
import img7 from './../Assets/Img/recep7.JPG';
import img8 from './../Assets/Img/recep8.JPG';
import img9 from './../Assets/Img/recep9.JPG';
import img10 from './../Assets/Img/recep10.JPG';
import CGV from "./../Assets/Pdf/CGV-particuliers.pdf";

class reception extends React.Component {

  constructor(props){
    super(props)
    this.state = {
        photoList : [
          img1,
          img2,
          img3,
          img4, 
          img5,
          img6,
          img7,
          img8,
          img9,
          img10,
        ]
    }
}

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render(){
    return(
    <div className="seminaire">
      <div className="sem-block">
        <div className="sem-card">
          <h1>DESCRIPTION</h1>
          <p>Le Golf de Béthune accueille vos repas de famille, vos communions, vos départs à la retraite tout au long de l’année.</p>
          <br/>
          <p>Notre salle de 120 m² peut recevoir idéalement 60 couverts et vous aurez accès à une terrasse équipée de chaises et de tables.</p>
          <br/>
          <p>Nous pouvons vous recevoir les samedis et dimanches midis, en soirée sur certaines conditions.</p>
        </div> 
        <div className="sem-img" style={{backgroundImage : "url(" + img1 + ")"}} />
      </div>
      <div className="sem-block">
        <div className="sem-img" style={{backgroundImage : "url(" + img2 + ")"}} />
        <div className="sem-card">
          <h1>TARIFS & OPTIONS</h1>
          <p>Selon la formule choisie, buffet, service à table ou cocktail dinatoire, nous vous établirons un devis en fonction de votre nombre d’invités.</p>
        </div> 
      </div>
      <div className="sem-block">
        <div className="sem-card">
          <h1>MODALITES DE RESERVATION</h1>
          <p>Votre réservation sera effective à la signature du contrat, au versement d’un premier acompte de 30 %.</p>
        </div> 
        <div className="sem-img"  style={{backgroundImage : "url(" + img3 + ")"}}/>
      </div>
      <div className="sem-block">
        <div className="sem-img" style={{backgroundImage : "url(" + img4 + ")"}} />
        <div className="sem-card">
          <h1>CONDITIONS D’UTILISATION DES ESPACES</h1>
          <div >
            <ul>
              <li>Aucun prestataire extérieur n’est admis au sein de l’établissement (sauf avec l’accord de la direction)</li>
            </ul>
          </div>
          <div className="CGV-btn"> 
                <a href={CGV} target="blank">
                  <button>Consulter les Conditions Générales de Vente</button>
                </a>
          </div> 
        </div> 
      </div> 
      <Mosaic 
          photoList={this.state.photoList}
        />
    </div>
    )
  }
}

export default reception;
