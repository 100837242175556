import React from 'react';
import { Link } from 'react-router-dom';

class Navigation extends React.Component {
  
  render(){
     const displayHeight = window.innerHeight - 95 + "px";
     
    return(
      <> 
      <nav style={this.props.mobileMenuIsOpen === true ? { transform: "translateX(0px)"} : {}} onMouseLeave={this.props.closeDropdown} className="nav-desktop">
        <ul>
          <li onClick={this.props.changeModbileMenuDisplay}>
             <Link to="/">Accueil</Link>
          </li>

          <li onClick={this.props.changeModbileMenuDisplay}>
             <Link to="/actualités">Actualités</Link>
          </li>
          <li>
             <button onClick={evt => this.props.changeDropDownMenuDisplay(evt, 1)}>Golf</button>
             <ul className="dropdown" style={this.props.menu1 === true ? {height : "198px", position : "absolute", background : "#B4D345"} : {height : "0px", position : "absolute", width : "0px"}}>
               <li onClick={this.props.changeModbileMenuDisplay}>
                  <Link to="/a-propos">A propos</Link>
               </li>
               <li onClick={this.props.changeModbileMenuDisplay}>
                  <Link to="/tarifs">Tarifs</Link>
               </li>
               <li onClick={this.props.changeModbileMenuDisplay}>
                  <Link to="/proshop">Pro Shop</Link>
               </li>
               <li onClick={this.props.changeModbileMenuDisplay}>
                 <Link to="/reglement">Règlement</Link>
               </li>
             </ul>
          </li>
          <li>
             <button onClick={evt => this.props.changeDropDownMenuDisplay(evt, 2)}>Enseignement</button>
             <ul className="dropdown" style={this.props.menu2 === true ? {height : "98px", position : "absolute", background : "#B4D345"} : {height : "0px", position : "absolute", width : "0px"}}>
               <li onClick={this.props.changeModbileMenuDisplay}>
                  <Link to="/cours">Cours</Link>
               </li>
               <li onClick={this.props.changeModbileMenuDisplay}>
                  <Link to="/ecole">Ecole de golf</Link>
               </li>
             </ul>
          </li>
          <li onClick={this.props.changeModbileMenuDisplay}>
             <Link to="/calendrier">Compétitions</Link>
          </li>
          <li>
             <button onClick={evt => this.props.changeDropDownMenuDisplay(evt, 3)}>Seminaires & Réceptions</button>
             <ul className="dropdown" style={this.props.menu3 === true ? {height : "98px", position : "absolute", background : "#B4D345"} : {height : "0px", width : "0px", position : "absolute"}}>
               <li onClick={this.props.changeModbileMenuDisplay}>
                  <Link to="/seminaire">Séminaire</Link>
               </li>
               <li onClick={this.props.changeModbileMenuDisplay}>
                 <Link to="/reception">Réceptions</Link>
               </li>
             </ul>
          </li>
          <li onClick={this.props.changeModbileMenuDisplay}>
             <Link to="/partenaires">Nos Partenaires</Link>
          </li>
          <li onClick={this.props.changeModbileMenuDisplay}>
             <Link to="/association-sportive">Association sportive</Link>
          </li>
        </ul>
      </nav>
      <nav style={this.props.mobileMenuIsOpen === true ? { transform: "translateX(0px)", height : displayHeight} : {height : displayHeight}} onMouseLeave={this.props.closeDropdown} className="nav-mobile">
        <ul>
          <li onClick={this.props.changeModbileMenuDisplay}>
             <Link to="/">Accueil</Link>
          </li>

          <li onClick={this.props.changeModbileMenuDisplay}>
             <Link to="/actualités">Actualités</Link>
          </li>
         <li onClick={this.props.changeModbileMenuDisplay}>
            <Link to="/a-propos">A propos</Link>
         </li>
         <li onClick={this.props.changeModbileMenuDisplay}>
            <Link to="/tarifs">Tarifs</Link>
         </li>
         <li onClick={this.props.changeModbileMenuDisplay}>
            <Link to="/proshop">Pro Shop</Link>
         </li>
         <li onClick={this.props.changeModbileMenuDisplay}>
                 <Link to="/reglement">Règlement</Link>
         </li>
         <li onClick={this.props.changeModbileMenuDisplay}>
            <Link to="/cours">Cours</Link>
         </li>
         <li onClick={this.props.changeModbileMenuDisplay}>
            <Link to="/ecole">Ecole de golf</Link>
         </li>
          <li onClick={this.props.changeModbileMenuDisplay}>
             <Link to="/calendrier">Compétitions</Link>
          </li>
         <li onClick={this.props.changeModbileMenuDisplay}>
            <Link to="/seminaire">Séminaire</Link>
         </li>
         <li onClick={this.props.changeModbileMenuDisplay}>
            <Link to="/reception">Anniversaire & Mariage</Link>
         </li>
          <li onClick={this.props.changeModbileMenuDisplay}>
             <Link to="/partenaires">Nos Partenaires</Link>
          </li>
          <li onClick={this.props.changeModbileMenuDisplay}>
             <Link to="/association-sportive">Association sportive</Link>
          </li>
          <li onClick={this.props.changeModbileMenuDisplay}>
             <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
      </>
    )
  }
}

export default Navigation;
