import React from 'react';


class Calendar extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      modalIsOpen : false,
      photoToDisplay : {url : "", index : 0}, 
      photoList : [],
      eventList : [
                    {name : "Amicale Golf Pétanque", date : "05/03/2024", startImg : undefined, resultImg : undefined},
                    {name : "Scramble de la Branche Morte, Fitting Wilson ", date : "10/03/2024", startImg : undefined, resultImg : undefined},
                    {name : "Trophée Into the Green/Ecole de Golf et Cartes Vertes", date : "24/03/2024", startImg : undefined, resultImg : undefined},
                    {name : "Amicale Golf Pétanque", date : "02/04/2024", startImg : undefined, resultImg : undefined},
                    {name : "Joker Trophy", date : "13/04/2024", startImg : undefined, resultImg : undefined},
                    {name : "Joker Trophy", date : "14/04/2024", startImg : undefined, resultImg : undefined},
                    {name : "Séniors Golf Entreprise HDF", date : "17/04/2024", startImg : undefined, resultImg : undefined},
                    {name : "Pitch & Putt au Golf du Bois des Retz", date : "20/04/2024", startImg : undefined, resultImg : undefined},
                    {name : "Amicale Golf Pétanque", date : "07/05/2024", startImg : undefined, resultImg : undefined},
                    {name : "Trophée des Clubs 9 trous HDF au Golf de Menneville", date : "17/05/2024", startImg : undefined, resultImg : undefined},
                    {name : "Trophée des Clubs 9 trous HDF au Golf de Menneville", date : "18/05/2024", startImg : undefined, resultImg : undefined},
                    {name : "Scramble « Petits-Fils » de l’Ecole de Golf", date : "18/05/2024", startImg : undefined, resultImg : undefined},
                    {name : "Trophée des Clubs 9 trous HDF au Golf de Menneville", date : "19/05/2024", startImg : undefined, resultImg : undefined},
                    {name : "Scramble « Petits-Fils »", date : "19/05/2024", startImg : undefined, resultImg : undefined},
                    {name : "Coupe de l’ASGCB", date : "25/05/2024", startImg : undefined, resultImg : undefined},
                    {name : "Only Girl’s", date : "02/06/2024", startImg : undefined, resultImg : undefined},
                    {name : "Amicale Golf Pétanque", date : "04/06/2024", startImg : undefined, resultImg : undefined},
                    {name : "Final Match Play", date : "08/06/2024", startImg : undefined, resultImg : undefined},
                    {name : "Fête de l’Ecole de Golf", date : "15/06/2024", startImg : undefined, resultImg : undefined},
                    {name : "Pitch & Putt au Golf de Béthune", date : "22/06/2024", startImg : undefined, resultImg : undefined},
                    {name : "Compétition Swisslife de l’Ecole de Golf", date : "29/06/2024", startImg : undefined, resultImg : undefined},
                    {name : "Compétition Swisslife", date : "30/06/2024", startImg : undefined, resultImg : undefined},
                    {name : "Amicale Golf Pétanque", date : "02/07/2024", startImg : undefined, resultImg : undefined},
                    {name : "Amicale Golf Pétanque", date : "06/08/2024", startImg : undefined, resultImg : undefined},
                    {name : "Portes Ouvertes", date : "31/08/2024", startImg : undefined, resultImg : undefined},
                    {name : "Portes Ouvertes", date : "01/09/2024", startImg : undefined, resultImg : undefined},
                    {name : "Amicale Golf Pétanque", date : "03/09/2024", startImg : undefined, resultImg : undefined},
                    {name : "Pitch & Putt au Golf d’Olhain", date : "14/09/2024", startImg : undefined, resultImg : undefined},
                    {name : "GOLF en fête ! Fitting Wilson", date : "15/09/2024", startImg : undefined, resultImg : undefined},
                    {name : "Trophée de la Ville Ecole de Golf S.N.H. Menuiseries", date : "28/09/2024", startImg : undefined, resultImg : undefined},
                    {name : "27è m e Trophée de la Ville S.N.H. Menuiseries", date : "29/09/2024", startImg : undefined, resultImg : undefined},     
                    {name : "Amicale Golf Pétanque", date : "01/10/2024", startImg : undefined, resultImg : undefined}, 
                    {name : "Octobre Rose de l’Ecole de Golf", date : "05/10/2024", startImg : undefined, resultImg : undefined},
                    {name : "Octobre Rose", date : "06/10/2024", startImg : undefined, resultImg : undefined},
                    {name : "Qualification Régionale Pitch & Putt Golf Entreprises", date : "12/10/2024", startImg : undefined, resultImg : undefined},
                    {name : "Sortie ASGCB au Golf d’Hardelot parcours Les Dunes", date : "12/10/2024", startImg : undefined, resultImg : undefined},
                    {name : "Amicale Golf Pétanque", date : "05/11/2024", startImg : undefined, resultImg : undefined},
                    {name : "Coupe du Beaujolais", date : "24/11/2024", startImg : undefined, resultImg : undefined},
                    {name : "Amicale Golf Pétanque", date : "03/12/2024", startImg : undefined, resultImg : undefined},
                  ]
    }
  }
  
  componentDidMount() {
    window.scrollTo(0,0);
  }

  openImg = (e, img) => {
    if (img.__proto__.constructor.name === "Array") {
      const photoListFormated = img.map((imgTmp, index) => ({ url : imgTmp, index}))
      this.setState({modalIsOpen : true, photoList : photoListFormated, photoToDisplay : photoListFormated[0]});
    }
    else{
      this.setState({modalIsOpen : true, photoToDisplay : {url : img, index : 0}});
    }
    
  }

  closeImg = () => {
    this.setState({modalIsOpen : false, photoList: [], photoToDisplay : {url : "", index : 0}})
  }

  next(index){
    const newIndex = index + 1;
    const newPhoto = this.state.photoList.find((photo, num) => num === newIndex);
    this.setState({photoToDisplay : newPhoto});
  }

  previous(index){
    const newIndex = index - 1;
    const newPhoto = this.state.photoList.find((photo, num) => num === newIndex);
    this.setState({photoToDisplay : newPhoto});
  }

  render(){
    const updatedEventList = this.state.eventList.map((evt) => {
      const originalFormatDate = evt.date; 
      const originalFormatDateSplited = originalFormatDate.split("/");
      const dateEnglishFormat = originalFormatDateSplited[1] + "/" + originalFormatDateSplited[0] + "/" + originalFormatDateSplited[2];
      return evt = {...evt, englishDate : dateEnglishFormat}
    })

    const pastEventList = updatedEventList.filter(evt =>{
      if (new Date() - new Date(evt.englishDate) > 0) {
        return true;
      } else {
        return false
      }
    })

    const futurEventList = updatedEventList.filter(evt =>{
      if (new Date() - new Date(evt.englishDate) < 0) {
        return true;
      } else {
        return false;
      }
    })

    pastEventList.sort((a,b) => {
      return new Date(b.englishDate) - new Date(a.englishDate);
    });

    futurEventList.sort((a,b) => {
      return new Date(a.englishDate) - new Date(b.englishDate);
    });

    return(
      <div className="calendar-container">
        <div className="gallery"> 
        {this.state.modalIsOpen === true ? <div className="modal">
                                            <img src={this.state.photoToDisplay.url} alt="compétition de golf" />
                                            <button onClick={this.closeImg}>X</button>
                                            <button className={this.state.photoToDisplay.index === 0 ? "none" : "btn-prev"} onClick={() => this.previous(this.state.photoToDisplay.index)}>{"<"}</button>
                                            <button className={this.state.photoToDisplay.index === this.state.photoList.length - 1 || this.state.photoList.length === 0 ? "none" : "btn-next"} onClick={() => this.next(this.state.photoToDisplay.index)}>{">"}</button>
                                           </div> : ''
        }
      </div>
        <h1>Compétitions à venir</h1>
        <table>
          <thead>
            <tr>
            <th>Evénement</th>
            <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {futurEventList.map((event, index) => {
              return(
                <tr key={index}>
                  <td>
                    <div style={{display : "flex", flexDirection : "column"}}>
                      {event.name}
                      <button 
                        style={event.resultImg === undefined || event.resultImg === null || event.resultImg === "" || event.resultImg === [] ? {display : "none"} : {}}
                        onClick={e => this.openImg(e, event.resultImg)}
                      >
                        Résultats
                        </button>
                    </div>
                  </td>
                  <td>
                    <div style={{display : "flex", flexDirection : "column"}}>
                      {event.date}
                      <button 
                        style={event.startImg === undefined || event.startImg === null || event.startImg === "" || event.resultImg === [] ? {display : "none"} : {}}
                        onClick={e => this.openImg(e, event.startImg)}
                      >
                        Départs
                      </button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <br/>
        <h1>Résultats</h1>
        <table>
          <thead>
            <tr>
            <th>Evénement</th>
            <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {pastEventList.map((event, index) => {
              return(
                <tr key={index}>
                  <td>
                    <div style={{display : "flex", flexDirection : "column"}}>
                      {event.name}
                      <button 
                        style={event.resultImg === undefined || event.resultImg === null || event.resultImg === "" || event.resultImg === [] ? {display : "none"} : {}}
                        onClick={e => this.openImg(e, event.resultImg)}
                      >
                        Résultats
                        </button>
                    </div>
                  </td>
                  <td>
                    <div style={{display : "flex", flexDirection : "column"}}>
                      {event.date}
                      <button 
                        style={event.startImg === undefined || event.startImg === null || event.startImg === "" || event.resultImg === [] ? {display : "none"} : {}}
                        onClick={e => this.openImg(e, event.startImg)}
                      >
                        Départs
                      </button>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default Calendar;
