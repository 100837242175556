import React, { cloneElement } from 'react';
import { Redirect } from 'react-router-dom';

class Contact extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      name : "",
      email : "",
      object : "",
      message : "",
      tel : "",
      errorMessage : "",
      validateMessage : ""
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
  }

  changeInputValue = (evt, inputName) => {
    switch (inputName) {
      case "name":
        this.setState({name : evt.target.value})
        break;
      case "email":
        this.setState({email : evt.target.value})
        break;
      case "tel":
          this.setState({tel : evt.target.value})
      break;
      case "object":
        this.setState({object : evt.target.value})
        break;
      case "message":
        this.setState({message : evt.target.value})
        break;
      default:
        console.error(evt);
        break;
    }
  }

  send = (e) => {
    e.preventDefault();

    if(this.state.name === ""){
      this.setState({errorMessage : "Veuillez renseigner votre nom"});
    }
    else if(this.state.email === ""){
      this.setState({errorMessage : "Veuillez renseigner votre E-mail"});
    }
    else if(this.state.tel === ""){
      this.setState({errorMessage : "Veuillez renseigner votre numéro de téléphone"});
    }
    else if(this.state.object === ""){
      this.setState({errorMessage : "Veuillez renseigner l'objet de votre message"});
    }
    else if(this.state.message === ""){
      this.setState({errorMessage : "Veuillez écrire un Message"});
    }
    else{
      fetch("http://golfdebethune.fr/email/email.php", {
        method: 'POST',
        body: JSON.stringify({name: encodeURI(this.state.name), object: encodeURI(this.state.object), email : this.state.email, tel : this.state.tel, message : encodeURI(this.state.message)})
      })
      .catch(function(err) {
        alert(err);  
      });
      this.setState({
        name : "",
        email : "",
        object : "",
        message : "",
        tel : "",
        errorMessage : "",
        validateMessage : "Message envoyé"
      })
    }
  }

  render(){
    return(
      <div className="contact-container">
        <form onSubmit={(e) => this.send(e)}>
          <h1>Contactez-nous</h1>
          <h3>Tél : 03 21 57 13 13</h3>
          <input type="text" placeholder="Nom" onChange={e => this.changeInputValue(e, "name")} value={this.state.name}/>
          <input type="email" placeholder="E-mail" onChange={e => this.changeInputValue(e, "email")} value={this.state.email}/>
          <input type="tel" placeholder="Téléphone" onChange={e => this.changeInputValue(e, "tel")} value={this.state.tel}/>
          <input type="text" placeholder="Objet" onChange={e => this.changeInputValue(e, "object")} value={this.state.object}/>
          <textarea placeholder="Mon Message" onChange={e => this.changeInputValue(e, "message")} value={this.state.message}></textarea>
          <div style={this.state.errorMessage !== "" ? {background : "red", color : "white", padding : "10px", display : "block", textAlign : "center", borderRadius : "50px", margin : "10px", fontWeight : "bold"} : {display : "none"}}>
            {this.state.errorMessage}
          </div>
          <div style={this.state.validateMessage !== "" ? {background : "#B4D345", color : "white", padding : "10px", display : "block", textAlign : "center", borderRadius : "50px", margin : "10px", fontWeight : "bold"} : {display : "none"}}>
            {this.state.validateMessage}
          </div>
          <input type="submit" value="Envoyer"/>
        </form>
      </div>
    )
  }
}

export default Contact;
