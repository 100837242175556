import React from 'react';
import './Assets/Css/App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './Views/Home';
import Prices from './Views/Prices';
import News from './Views/News';
import Calendar from './Views/Calendar';
import Courses from './Views/Courses';
import Proshop from './Views/Proshop';
import Seminaire from './Views/Seminaire';
import Contact from './Views/Contact';
import About from './Views/About';
import Partenaires from './Views/Partenaires';
import Rules from './Views/Rules';
import Reception from './Views/Reception';
import School from './Views/School';
import SportAssociation from './Views/SportAssociation';
import Backoffice from './Views/Backoffice';
import Header from './Components/Header';
import Navigation from './Components/Navigation';
import Rs from './Components/Rs.jsx';
import Footer from './Components/Footer';

class App extends React.Component {

  constructor(props){
    super(props)
    this.state = {
       menu1 : false,
       menu2 : false,
       menu3 : false,
       mobileMenuIsOpen : false,
    }
 }

 changeDropDownMenuDisplay = (evt, num) => {
    if(num === 1){
       this.setState({
          menu1 : true,         
          menu2 : false,
          menu3 : false,
       })
    }
    if(num === 2){
       this.setState({
          menu1 : false,
          menu2 : true,
          menu3 : false,
       })
    }
    if(num === 3){
       this.setState({
          menu1 : false,
          menu2 : false,
          menu3 : true,
       })
    }
 }

 changeModbileMenuDisplay = () => {
    if (this.state.mobileMenuIsOpen === true) {
      this.setState({
       menu1 : false,
       menu2 : false,
       menu3 : false,
       mobileMenuIsOpen : false
    });
    }
    else {
      this.setState({
       menu1 : false,
       menu2 : false,
       menu3 : false,
       mobileMenuIsOpen : true
      });
    }
  }

  closeDropdown = () => {
     this.setState({
       menu1 : false,
       menu2 : false,
       menu3 : false,
     })
  }

  render() {
    return (
        <Router>
          <Header 
            changeModbileMenuDisplay={this.changeModbileMenuDisplay}
          />
          <Navigation
            changeDropDownMenuDisplay={this.changeDropDownMenuDisplay}
            closeDropdown={this.closeDropdown}
            changeModbileMenuDisplay={this.changeModbileMenuDisplay}
            menu1={this.state.menu1}
            menu2={this.state.menu2}
            menu3={this.state.menu3}
            mobileMenuIsOpen={this.state.mobileMenuIsOpen}
          />
          <main>
            <Switch>
              <Route path="/backoffice">
                <Backoffice />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/a-propos">
                <About />
              </Route>
              <Route path="/actualités">
                <News />
              </Route>
              <Route path="/reception">
                <Reception />
              </Route>
              <Route path="/ecole">
                <School />
              </Route>
              <Route path="/partenaires">
                <Partenaires />
              </Route>
              <Route path="/reglement">
                <Rules />
              </Route>
              <Route path="/seminaire">
                <Seminaire />
              </Route>
              <Route path="/proshop">
                <Proshop />
              </Route>
              <Route path="/association-sportive">
                <SportAssociation />
              </Route>
              <Route path="/tarifs">
                <Prices />
              </Route>
              <Route path="/calendrier">
                <Calendar />
              </Route>
              <Route path="/cours">
                <Courses />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </main>
          <Rs/>
          <Footer/>
        </Router>
    );
  }
}

export default App;
