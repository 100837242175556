import React from 'react';
import bg from './../Assets/Img/rules.jpg';
import rules from "./../Assets/Pdf/regle.pdf";

class Rules extends React.Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render(){
    return(
      <div className="rules" style={{backgroundImage : "url(" + bg + ")"}}>
        <div>
          <h1>Règlement</h1>
          <h4>Par mesure de sécurité, tout joueur doit obligatoirement se présenter au club-house avant de se rendre au practice ou sur le parcours</h4>
          <h2>Règle locale</h2>
          <p>Au départ du Trou n°1, vous devez obligatoirement attendre que les joueurs vous précédant quittent le départ du Trou n°2 avant de jouer votre coup</p>
          <h2>Putting green</h2>
          <ul>
            <li>Balle de practice interdite</li>
            <li>Approche lobbée interdite</li>
          </ul>
          <h2>Practice</h2>
          <ul>
            <li>Practice de 200 m, il est de votre responsabilité de contrôler vos distances si vous avez le potentiel de dépasser le filet et le green du trou n°2</li>
            <li>Jeu interdit sur le gazon</li>
            <li>Interdiction de ramasser les balles sur le practice</li>
            <li>Jeu interdit lors du ramassage de balles</li>
            <li>Modérer votre jeu lors de la visite de nos scolaires</li>
          </ul>
          <h2>Parcours</h2>
          <ul>
            <li>Licence obligatoire</li>
            <li>Seules les règles éditées par la FFG sont valables sur le terrain</li>
            <li>Joueurs, visiteurs veuillez régler votre green fee avant de prendre le départ</li>
            <li>Balle de practice interdite</li>
            <li>Sur le départ toujours se placer devant le joueur à l'adresse</li>
            <li>Dans la mesure du possible, partie de 3 joueurs maximum</li>
            <li>Priorité aux joueurs jouant sur le parcours en intégralité</li>
            <li>Chariots interdits sur les départs</li>
            <li>Toujours attendre que les joueurs vous précédant soient hors de portée</li>
          </ul>
          <h2>L'étiquette</h2>
          <p>L'étiquette, c'est le respect des autres et de l'environnement</p>
          <ul>
            <li>Ratissez vos traces dans les bunkers</li>
            <li>Respecter le silence sur le parcours</li>
            <li>Réparer vos marques de balles sur les greens</li>
            <li>Garder vos distances entre les parties</li>
            <li>Replacez vos mottes de gazon</li>
          </ul>
          <a href={rules} target="_blank" style={{textDecoration : "none"}}><button>Consulter les règles officielles du Golf</button></a>
        </div>
      </div>
    )
  }
}

export default Rules;
